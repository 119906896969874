import React from "react";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import InternalNavWidget from "../components/widgets/internalNavWidget";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

// CSS
import '../styles.css'
import styleGuideContent from "../components/local/resources/internal-style-guide-content";

const StyleGuide = () => {
   const navigation_options = {
      "001": {
         "Buttons": 'buttons'
      },
      "002": {
         "Tables": 'tables'
      },
      "003": {
         "CTAs": 'ctas'
      },
      "004": {
         "Boxes": 'boxes'
      },
      "005": {
         "Lists": "lists"
      },
      "006": {
         "Images": "images"
      },
      "007": {
         "Icons": "icons"
      }
   }

   return (
      <React.Fragment>
         <SEO
            title="Internal style guide"
            slug="internal-style-guide"
            metaDescription="Use this page to implement styling into your Appliance Sure articles & pages. This page will be updated systematically with any changes."
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>Internal style guide</h1>
               <p>Use this page to implement styling into your Appliance Sure articles & pages. This page will be updated systematically with any changes.</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-split-container wide-width">
               <div className="content-container">
                  <Breadcrumbs
                     location="internal-style-guide"
                  />

                  {/* Styles content */}
                  <h2 id="buttons">Buttons</h2>

                  <h3>Major CTA button</h3>

                  <div className="center-element-wrapper">
                     <a href="https://github.com" className="standard-button green" target="_blank">Sign up now</a>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.buttons.cta}
                     language="html"
                     theme={dracula}
                  />
                  
                  <h3>Standard button</h3>

                  <div className="center-element-wrapper">
                     <a href="https://github.com" className="standard-button primary" target="_blank">See all appliances</a>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.buttons.primary}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Secondary button</h3>

                  <div className="center-element-wrapper">
                     <a href="https://github.com" className="standard-button secondary" target="_blank">Find out more</a>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.buttons.secondary}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Page placement</h3>

                  <CodeBlock
                     text={styleGuideContent.buttons.placement}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Dual buttons</h3>
                  
                  <div className="center-element-wrapper">
                     <div className="stacked-button-container">
                        <a href="https://github.com" className="standard-button green" target="_blank">Sign up </a>
                        <a href="https://github.com" className="standard-button secondary" target="_blank">Find out more</a>
                     </div>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.buttons.dual}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  <hr/>

                  <h2 id="tables">Tables</h2>

                  <h3>Standard table</h3>

                  <div className="table-wrapper">
                     <table>
                        <tbody>
                           <tr>
                              <th>Country</th>
                              <th>Size (km²)</th>
                              <th>Population</th>
                           </tr>
                           <tr>
                              <td>United Kingdom</td>
                              <td>243,610</td>
                              <td>67,508,936</td>
                           </tr>
                           <tr>
                              <td>Colombia</td>
                              <td>1,139,000</td>
                              <td>52,088,434</td>
                           </tr>
                           <tr>
                              <td>Russia</td>
                              <td>17,100,000</td>
                              <td>146,711,554</td>
                           </tr>
                           <tr>
                              <td>Ghana</td>
                              <td>238,533</td>
                              <td>33,475,870</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.tables.standard}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Product information table</h3>

                  <div className="table-wrapper">
                     <table className="product-information-table">
                        <tbody>
                           <tr>
                              <td>Region</td>
                              <td>eu-west-2 (Europe/London)</td>
                           </tr>
                           <tr>
                              <td>Runtime</td>
                              <td>Node.js v16.13.1</td>
                           </tr>
                           <tr>
                              <td>Server distribution</td>
                              <td>Linux CentOS v7.9.2009</td>
                           </tr>
                           <tr>
                              <td>Processor</td>
                              <td>Intel (R) Xeon (R) Gold 5220 CPU @ 2.20GHz</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.tables.product_information}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Table with highlighted row</h3>

                  <div className="table-wrapper table-with-highlight">
                     <table>
                        <tbody>
                           <tr>
                              <th>Country</th>
                              <th>Size (km²)</th>
                              <th>Population</th>
                           </tr>
                           <tr>
                              <td>United Kingdom</td>
                              <td>243,610</td>
                              <td>67,508,936</td>
                           </tr>
                           <tr className="highlighted-table-row">
                              <td>Colombia</td>
                              <td>1,139,000</td>
                              <td>52,088,434</td>
                           </tr>
                           <tr>
                              <td>Russia</td>
                              <td>17,100,000</td>
                              <td>146,711,554</td>
                           </tr>
                           <tr>
                              <td>Ghana</td>
                              <td>238,533</td>
                              <td>33,475,870</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.tables.table_with_hightlighted_row}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Table with highlighted row & label</h3>

                  <div className="table-wrapper table-with-highlight">
                     <table>
                        <tbody>
                           <tr>
                              <th>Country</th>
                              <th>Size (km²)</th>
                              <th>Population</th>
                           </tr>
                           <tr>
                              <td>United Kingdom</td>
                              <td>243,610</td>
                              <td>67,508,936</td>
                           </tr>
                           <label className="highlighted-table-row-label"><i class="fa-solid fa-house"></i> Home country</label>
                           <tr className="highlighted-table-row with-label">
                              <td>Colombia</td>
                              <td>1,139,000</td>
                              <td>52,088,434</td>
                           </tr>
                           <tr>
                              <td>Russia</td>
                              <td>17,100,000</td>
                              <td>146,711,554</td>
                           </tr>
                           <tr>
                              <td>Ghana</td>
                              <td>238,533</td>
                              <td>33,475,870</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.tables.table_with_hightlighted_row_and_label}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  <hr/>

                  <h2 id="ctas">Call to actions (CTAs)</h2>

                  <p>All CTAs use shortcode to ensure that CTAs are unified and can be updated centrally without directly editing source code. Simply copy the shortcode into your HTML where you'd like it to go without any wrappers or containers.</p>

                  <h3>Get covered</h3>

                  <div className="standard-cta-container">
                     <span>
                        <h3><span className="cta-highlight">Peace of mind</span> <span>for your washing machine 🤩</span></h3>
                        <p>For as little as £6.50, you could cover your washing machine against breakdowns giving you peace of mind that if anything goes wrong, we'll be there.</p>
                     </span>

                     <a className="cta-button phone" href="#">0203&nbsp;953&nbsp;6001</a>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.ctas.cover_appliance}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Get advice</h3>

                  <div className="standard-cta-container secondary">
                     <span>
                        <h3><span className="cta-highlight">Get free help</span> <span>with your washing machine 💪</span></h3>
                        <p>Call our expert engineering team who will walk you through the issues you're having with your washing machine, whether you have a plan or not.</p>
                     </span>

                     <a className="cta-button phone" href="#">0203&nbsp;953&nbsp;6001</a>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.ctas.free_advice}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  <hr/>

                  <h2 id="boxes">Boxes</h2>

                  <h3>Info box</h3>

                  <div className="box-container info">
                     <span>
                        <h3>Did you know?</h3>
                        <p><strong>...that it’s illegal to feed pigeons on the sidewalks and streets in San Francisco?</strong> Sure, a sprinkle of breadcrumbs to feed hungry birds may seem harmless but in the popular Californian city, you could get into trouble. San Francisco's Public Works Department explains this law is in place because there "are dozens of reasons why" you shouldn't feed your new pigeon friends.</p>
                     </span>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.boxes.info}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Warning box</h3>

                  <div className="box-container warning">
                     <span>
                        <h3>Warning</h3>
                        <p>High pressure in the summer often brings fine, warm weather. It can lead to long warm sunny days and prolonged dry periods. In severe situations, this can cause a drought (such as early 2012). Heatwaves can also become a hazard (such as the record-breaking hot temperatures across Europe during the summer of 2003).</p>
                     </span>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.boxes.warning}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Features box</h3>

                  <div className="box-container features">
                     <span>
                        <h3>New iOS 16 features</h3>
                        <p>Apple in June 2022 previewed the new version of the iPhone's operating system, iOS 16. iOS 16 introduces redesigned, customizable Lock Screens with widgets, the ability to edit and delete messages, improved Focus modes, an iCloud Shared Photos Library for families, major improvements for apps like Mail, Home, and Wallet, and much more.</p>
                     </span>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.boxes.features}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  <hr/>

                  <h2 id="lists">Lists</h2>

                  <h3>Standard unordered list</h3>

                  <ul>
                     <li>Pound Sterling</li>
                     <li>Colombian Peso</li>
                     <li>Russian Ruble</li>
                     <li>Ghanaian Cedi</li>
                  </ul>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.lists.standard_ul}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Positives list</h3>

                  <ul className="positives-list">
                     <li>Full stack Javascript</li>
                     <li>Asynchronous runtime</li>
                     <li>NPM package manager</li>
                     <li>Efficiency of requests handling</li>
                  </ul>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.lists.positives_list}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Negatives list</h3>

                  <ul className="negatives-list">
                     <li>API stability issues</li>
                     <li>Not suitable for heavy-compute</li>
                     <li>Immaturity of tools</li>
                     <li>Robust library system</li>
                  </ul>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.lists.negatives_list}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Pros & cons comparison</h3>

                  <table>
                     <tbody>
                        <tr>
                           <th>Pros</th>
                           <th>Cons</th>
                        </tr>
                        <tr>
                           <td>
                              <ul className="positives-list">
                                 <li>Full stack Javascript</li>
                                 <li>Asynchronous runtime</li>
                                 <li>NPM package manager</li>
                                 <li>Efficiency of requests handling</li>
                              </ul>
                           </td>
                           <td>
                              <ul className="negatives-list">
                                 <li>API stability issues</li>
                                 <li>Not suitable for heavy-compute</li>
                                 <li>Immaturity of tools</li>
                                 <li>Robust library system</li>
                              </ul>
                           </td>
                        </tr>
                     </tbody>
                  </table>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.lists.pros_cons}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Standard ordered list</h3>

                  <ol>
                     <li>Javascript</li>
                     <li>Tailwind</li>
                     <li>ReactJS</li>
                     <li>MongoDB</li>
                     <li>DynamoDB</li>
                  </ol>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.lists.standard_ol}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Step list</h3>

                  <ol className="step-list">
                     <li>Node js basic concepts - Libuv, Event loop, Libev</li>
                     <li>Building a Simple Web Server in Node.js</li>
                     <li>Node.js modules and NPM</li>
                     <li>File system module</li>
                  </ol>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.lists.step_list}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  <hr/>

                  <h2 id="images">Images</h2>

                  <p>That maximum dimensions for images inside of the content container is 350px in height & 100% of container width in width. Max image size: 853px x 350px at largest screen size.</p>

                  <h3>Standard image</h3>

                  <div className="center-element-wrapper">
                     <img alt="Golden labrador puppy" title="Puppy image" src="/images/dog-image-example.png"/>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.images.standard}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Image sizing</h3>

                  <img alt="Golden labrador puppy" title="Large image" src="/images/dog-image-example.png"/>

                  <img className="medium-image" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>

                  <img className="small-image" alt="Golden labrador puppy" title="Small image" src="/images/dog-image-example.png"/>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.images.image_sizing}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  
                  <h3>Image with caption</h3>

                  <div className="center-element-wrapper">
                     <figure className="image-with-caption">
                        <img src="/images/dogs-playing-example.jpeg"/>
                        <figcaption>Dogs playing together with a stick. Source: <a href="https://www.youtube.com/watch?v=FHytoCvj90w" target="_blank" rel="nofollow">Youtube.com</a></figcaption>
                     </figure>
                  </div>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.images.image_caption}
                     language="html"
                     theme={dracula}
                  />

                  <br/>

                  <h3>Page placement</h3>
                  
                  <div className="center-element-wrapper">
                     <img className="medium-image" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>
                  </div>

                  <p>As an asynchronous event-driven JavaScript runtime, Node.js is designed to build scalable network applications. In the following "hello world" example, many connections can be handled concurrently. Upon each connection, the callback is fired, but if there is no work to be done, Node.js will sleep.</p>

                  <img className="medium-image float-left" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>
                  
                  <p>This is in contrast to today's more common concurrency model, in which OS threads are employed. Thread-based networking is relatively inefficient and very difficult to use. Furthermore, users of Node.js are free from worries of dead-locking the process, since there are no locks. Almost no function in Node.js directly performs I/O, so the process never blocks except when the I/O is performed using synchronous methods of Node.js standard library. Because nothing blocks, scalable systems are very reasonable to develop in Node.js.</p>

                  <p>If some of this language is unfamiliar, there is a full article on Blocking vs. Non-Blocking.</p>

                  <img className="medium-image float-right" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>

                  <p>Node.js is similar in design to, and influenced by, systems like Ruby's Event Machine and Python's Twisted. Node.js takes the event model a bit further. It presents an event loop as a runtime construct instead of as a library. In other systems, there is always a blocking call to start the event-loop. Typically, behavior is defined through callbacks at the beginning of a script, and at the end a server is started through a blocking call like EventMachine::run(). In Node.js, there is no such start-the-event-loop call. Node.js simply enters the event loop after executing the input script. Node.js exits the event loop when there are no more callbacks to perform. This behavior is like browser JavaScript — the event loop is hidden from the user.</p>

                  <p>HTTP is a first-class citizen in Node.js, designed with streaming and low latency in mind. This makes Node.js well suited for the foundation of a web library or framework.</p>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.images.placement}
                     language="html"
                     theme={dracula}
                  />

                  <br/>
                  <hr/>

                  <h2 id="icons">Icons</h2>

                  <p>On the Appliance Sure website, we use FontAwesome icons. They are imported via CDN for you to use freely with the use of a simple HTML tag. You can follow the link below to view the available icons.</p>

                  <br/>

                  <div className="center-element-wrapper">
                     <a href="https://fontawesome.com/icons" target="_blank" className="standard-button primary">View 19,000+ icons</a>
                  </div>
                  

                  <h3>Example usage</h3>

                  <p>Call us on <a href="tel:+442039536001"><i class="fa-solid fa-phone"></i> 0203 953 6001</a></p>

                  <br/>
                  <br/>

                  <CodeBlock
                     text={styleGuideContent.icons.example}
                     language="html"
                     theme={dracula}
                  />

               </div>

               <div className="side-bar-container">
                  <div className="side-bar-entries">
                     <InternalNavWidget navigation={JSON.stringify(navigation_options)}/>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default StyleGuide