const styleGuideContent = {
   buttons: {

// CTA button
cta: 
`<div class="center-element-wrapper">
   <a class="standard-button green" href="https://github.com" target="_blank">Sign up now</a>
</div>`,

// Primary button
primary: 
`<div class="center-element-wrapper">
   <a class="standard-button primary" href="https://github.com" target="_blank">See all appliances</a>
</div>`,

// Secondary button
secondary: 
`<div class="center-element-wrapper">
   <a class="standard-button secondary" href="https://github.com" target="_blank">Find out more</a>
</div>`,

// Placement
placement:
`<!-- Left placement -->
<a class="standard-button primary" href="https://github.com" target="_blank">Click here</a>

<!-- Center placement -->
<div class="center-element-wrapper">
   <a class="standard-button primary" href="https://github.com" target="_blank">Click here</a>
</div>

<!-- Right placement -->
<div class="right-element-wrapper">
   <a class="standard-button primary" href="https://github.com" target="_blank">Click here</a>
</div>`,

// Dual buttons
dual:
`<div class="center-element-wrapper">
   <div class="stacked-button-container">
      <a href="https://github.com" class="standard-button green" target="_blank">Sign up </a>
      <a href="https://github.com" class="standard-button secondary" target="_blank">Find out more</a>
   </div>
</div>`

   },
   tables: {

// Standard
standard:
`<div class="table-wrapper">
   <table>
      <tbody>
         <tr>
            <th>Country</th>
            <th>Size (km²)</th>
            <th>Population</th>
         </tr>
         <tr>
            <td>United Kingdom</td>
            <td>243,610</td>
            <td>67,508,936</td>
         </tr>
         [...]
      </tbody>
   </table>
</div>`,

// Product information
product_information:
`<div class="table-wrapper">
   <table class="product-information-table">
      <tbody>
         <tr>
            <td>Region</td>
            <td>eu-west-2 (Europe/London)</td>
         </tr>
         <tr>
            <td>Runtime</td>
            <td>Node.js v16.13.1</td>
         </tr>
         [...]
      </tbody>
   </table>
</div>`,

// Table with highlighted row
table_with_hightlighted_row:
`<div class="table-wrapper table-with-highlight">
   <table>
      <tbody>
         <tr>
            <th>Country</th>
            <th>Size (km²)</th>
            <th>Population</th>
         </tr>
         <tr>
            <td>United Kingdom</td>
            <td>243,610</td>
            <td>67,508,936</td>
         </tr>
         <tr class="highlighted-table-row">
            <td>Colombia</td>
            <td>1,139,000</td>
            <td>52,088,434</td>
         </tr>
         [...]
      </tbody>
   </table>
</div>`,

// Table with highlighted row & label
table_with_hightlighted_row_and_label:
`<div class="table-wrapper table-with-highlight">
<table>
   <tbody>
      <tr>
         <th>Country</th>
         <th>Size (km²)</th>
         <th>Population</th>
      </tr>
      <!-- Label must go directly above highlighted <tr> -->
      <label class="highlighted-table-row-label"><i class="fa-solid fa-house"></i> Home country</label>
      <tr class="highlighted-table-row with-label">
         <td>United Kingdom</td>
         <td>243,610</td>
         <td>67,508,936</td>
      </tr>
      <tr>
         <td>Colombia</td>
         <td>1,139,000</td>
         <td>52,088,434</td>
      </tr>
      [...]
   </tbody>
</table>
</div>`

   },
   ctas: {

// Cover your [appliance]
cover_appliance:
`<!-- Will return an appliance-specific CTA relative to URL path -->
[cover_your_appliance_cta]`,


// Get advice
free_advice:
`<!-- Will return an appliance-specific CTA relative to URL path -->
[get_free_help_cta]`

   },
   boxes: {

// Info box
info:
`<div class="box-container info">
   <span>
      <h3>Did you know?</h3>
      <p><strong>...that it’s illegal to feed pigeons on the sidewalks [...] </strong> [...] </p>
   </span>
</div>`,

// Warning box
warning:
`<div class="box-container warning">
   <span>
      <h3>Warning</h3>
      <p>High pressure in the summer often brings fine, [...]</p>
   </span>
</div>`,

// Features box
features:
`<div class="box-container features">
   <span>
      <h3>New iOS 16 features</h3>
      <p>Apple in June 2022 previewed the new version of the iPhone's [...]</p>
   </span>
</div>`,

   },
   lists: {

// Standard UL
standard_ul:
`<ul>
   <li>Pound Sterling</li>
   <li>Colombian Peso</li>
   <li>Russian Ruble</li>
   <li>Ghanaian Cedi</li>
</ul>`,

// Positives list
positives_list:
`<ul class="positives-list">
   <li>Full stack Javascript</li>
   <li>Asynchronous runtime</li>
   <li>NPM package manager</li>
   <li>Efficiency of requests handling</li>
</ul>`,

// Negatives list
negatives_list:
`<ul class="negatives-list">
   <li>API stability issues</li>
   <li>Not suitable for heavy-compute</li>
   <li>Immaturity of tools</li>
   <li>Robust library system</li>
</ul>`,

// Pro cons comparison
pros_cons:
`<table>
   <tbody>
      <tr>
         <th>Pros</th>
         <th>Cons</th>
      </tr>
      <tr>
         <td>
            <ul class="positives-list">
               <li>Full stack Javascript</li>
               <li>Asynchronous runtime</li>
               <li>NPM package manager</li>
               <li>Efficiency of requests handling</li>
            </ul>
         </td>
         <td>
            <ul class="negatives-list">
               <li>API stability issues</li>
               <li>Not suitable for heavy-compute</li>
               <li>Immaturity of tools</li>
               <li>Robust library system</li>
            </ul>
         </td>
      </tr>
   </tbody>
</table>`,

// Standard OL>
standard_ol:
`<ol>
   <li>Javascript</li>
   <li>Tailwind</li>
   <li>ReactJS</li>
   <li>MongoDB</li>
   <li>DynamoDB</li>
</ol>`,

// Step list
step_list:
`<ol class="step-list">
   <li>Node js basic concepts - Libuv, Event loop, Libev</li>
   <li>Building a Simple Web Server in Node.js</li>
   <li>Node.js modules and NPM</li>
   <li>File system module</li>
</ol>`

   },
   images: {

// Standard
standard:
`<div class="center-element-wrapper">
   <img alt="Golden labrador puppy" title="Puppy image" src="/images/dog-image-example.png"/>
</div>`,

// Image sizing
image_sizing:
`<!-- Large image -->
<img alt="Golden labrador puppy" title="Large image" src="/images/dog-image-example.png"/>

<!-- Medium image -->
<img class="medium-image" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>

<!-- Small image -->
<img class="small-image" alt="Golden labrador puppy" title="Small image" src="/images/dog-image-example.png"/>`,

// Image with caption
image_caption:
`<div class="center-element-wrapper">
   <figure class="image-with-caption">
      <img src="/images/dogs-playing-example.jpeg"/>
      <figcaption>Dogs playing together with a stick. Source: <a href="https://www.youtube.com/watch?v=FHytoCvj90w" target="_blank" rel="nofollow">Youtube.com</a></figcaption>
   </figure>
</div>`,

// Page positioning
placement:
`<!-- Center placement -->
<div class="center-element-wrapper">
   <img class="medium-image" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>
</div>

<!-- Left placement -->
<img class="medium-image float-left" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>

<!-- Right placement -->
<img class="medium-image float-right" alt="Golden labrador puppy" title="Medium image" src="/images/dog-image-example.png"/>
`

   },
   icons: {

// Examples
example:
`<p>Call us on <a href="tel:+442039536001"><i class="fa-solid fa-phone"></i> 0203 953 6001</a></p>`
   }

   }

export default styleGuideContent