import React from "react";

const InternalNavWidget = ({ navigation }) => {
   const navJSON = JSON.parse(navigation)
   const navArray = Object.entries(navJSON)

   const createOptions = () => {
      let jsx = []

      for (let i = 0; i < navArray.length; i++) {
         const el = navArray[i][1];
         const options = Object.entries(el)

         jsx.push(
            <option value={options[0][1]}>{options[0][0]}</option>
         )   
      }

      return jsx
   }

   const handleNavigateTo = (event) => {
      const val = event.target.value;

      document.querySelector(`#${val}`).scrollIntoView({ behavior: 'smooth' })
   }

   return (
      <React.Fragment>
         <div className="nav-widget-container">
            <h3>Skip to content &nbsp;<i class="fa-solid fa-forward-step"></i></h3>

            <select onChange={handleNavigateTo} value="">
               <option value="" selected disabled>Choose a section</option>
               {createOptions()}
            </select>
         </div>
      </React.Fragment>
   )
}

export default InternalNavWidget